<template>
<div class="app-container">
  <el-col :sm="24" :md="16" >
    <el-card class="box-card">
      <div slot="header" class="clearfix">
        <span>Editar Recurso</span>
      </div>
      <div class="text item">
        <ValidationObserver ref="form" v-slot="{ invalid }">
          <el-form label-position="top" label-width="100px" :model="form">
             <!-- <el-form-item label="Curso">
                 <select v-model="form.title" multiple class="width-100">
                    <option v-for="itemWork in listItemsCoursesTitle" :key="itemWork.id">
                      {{itemWork}}
                    </option>
                  </select>
              </el-form-item> -->
               <el-form-item label="Curso">
                <ValidationProvider name="course" rules="required" v-slot="{ errors }">
                  <el-select
                  class="width-100"
                  size="mini"
                  v-model="form.course_id"
                  filterable
                  placeholder="título">
                    <el-option
                      v-for="itemCourses in listItemsCoursesTitle"
                      :key="itemCourses.value"
                      :label="itemCourses.title"
                      :value="itemCourses._id">
                    </el-option>
                  </el-select>
                  <span>{{ errors[0] }}</span>
                </ValidationProvider>
          </el-form-item>
              <el-form-item label="Título" size="mini">
                <ValidationProvider name="Titulo" rules="required" v-slot="{ errors }">
                  <el-input v-model="form.title"></el-input>
                  <span>{{ errors[0] }}</span>
                </ValidationProvider>
              </el-form-item>
              <el-form-item label="Descripción">
                <tinyeditor v-model="form.description"
                  api-key='epme9s1ejp9yit6h3lokaj05yohsvjm61r9y7kdrvm0wm9ex'
                    :init="{
                      height: 250,
                      plugins: [
                        'advlist autolink lists link image charmap print preview anchor',
                        'searchreplace visualblocks code fullscreen',
                        'insertdatetime media table paste code help wordcount'
                      ],
                      toolbar:
                        'undo redo | formatselect | bold italic backcolor | \
                        alignleft aligncenter alignright alignjustify | \
                        bullist numlist outdent indent | removeformat | help'
                    }"
                  />
              </el-form-item>
              <el-form-item label="Duración">
                <ValidationProvider name="duration" rules="required" v-slot="{ errors }">
                  <el-input size="mini" v-model="form.duration"></el-input>
                  <span>{{ errors[0] }}</span>
                </ValidationProvider>
              </el-form-item>

            <!-- Video -->
              <div v-if="form.type == 'video'">
                <el-form-item label="Url de video">
                  <el-input size="mini" v-model="form.video"></el-input>
                </el-form-item>
              </div>

                <!-- Audio -->
              <div v-if="form.type == 'audio'">
                <el-form-item label="Archivo audio .mp3">
                  <audio controls>
                    <source :src="$urlGlobalApi +form.audio" type="audio/mp3">
                          Tu navegador no soporta audio HTML5.
                  </audio>
                  <el-upload
                    name="file"
                    action="#"
                    :http-request="postAudio"
                    :on-success="handleAvatarSuccessAudio"
                    multiple>
                    <el-button slot="trigger" size="small" type="primary">Selecciona un archivo</el-button>
                    <!-- <i class="el-icon-upload"></i> -->
                    <!-- <div class="el-upload__text">Suelta tu Archivo aquí o <em>haz clic para cargar</em></div> -->
                  </el-upload>
                </el-form-item>
              </div>
              <!--PDF -->
              <div v-if="form.type == 'pdf'">
              <el-form-item label="Archivo PDF">
                <el-upload
                  name="file"
                  action="#"
                  :http-request="postFile"
                  :on-success="handleAvatarSuccessFile"
                  class="upload-demo"
                  multiple>
                  <el-button size="small" type="primary">Clic para subir archivo</el-button>
                  <div slot="tip" class="el-upload__tip">Solo archivos .pdf </div>
                </el-upload>
              </el-form-item>
              </div>
              <!--Contenido HTML -->
               <div v-if="form.type == 'index'">
                <el-form-item label="Contenido">
                 <tinyeditor v-model="form.content"
                  api-key='epme9s1ejp9yit6h3lokaj05yohsvjm61r9y7kdrvm0wm9ex'
                    :init="{
                      height: 500,
                      plugins: [
                        'advlist autolink lists link image charmap print preview anchor',
                        'searchreplace visualblocks code fullscreen',
                        'insertdatetime media table paste code help wordcount'
                      ],
                      toolbar:
                        'undo redo | formatselect | bold italic backcolor | \
                        alignleft aligncenter alignright alignjustify | \
                        bullist numlist outdent indent | removeformat | help'
                    }"
                  />
                </el-form-item>
              </div>
              <!--orden -->
              <el-form-item label="Orden">
                <ValidationProvider name="order" rules="required" v-slot="{ errors }">
                  <el-input size="mini" v-model="form.order"></el-input>
                  <span>{{ errors[0] }}</span>
                </ValidationProvider>
              </el-form-item>
              <el-form-item label="Estatus">
                <el-switch
                  v-model="form.status"
                  active-color="#13ce66"
                  inactive-color="#ff4949">
                </el-switch>
              </el-form-item>
              <el-form-item>
              <el-button
                @click.stop.prevent="onUpdate"
                type="primary"
                :disabled="invalid"
                :loading="loading"
                icon="el-icon-upload2">
                Actualizar</el-button>
              </el-form-item>
          </el-form>
        </ValidationObserver>
      </div>
    </el-card>
  </el-col>
</div>
</template>
<script>
import { getResources, updateResources } from '@/api/resources'
import { uploadImageFile } from '@/api/image'
import { getCourseTitle } from '@/api/listItems'
import { uploadFile } from '@/api/fileUpload'

export default {
  data () {
    return {
      form: {
        type: '',
        course_id: '',
        title: '',
        description: '',
        duration: '',
        video: '',
        order: 0,
        status: false,
        audio: '',
        image: '',
        file: '',
        content: ''
      },
      listItemsCoursesTitle: [],
      loading: false,
      loadingDelete: false,
      isVisibleCollection: false,
      formEditCollection: {},
      optionStatus: [{
        value: true,
        label: 'Publicado'
      }, {
        value: false,
        label: 'No Publicado'
      }]
    }
  },
  created () {
    this.fetchData()
    this.listResourcesTitle()
  },
  methods: {
    async fetchData () {
      this.loading = true
      await getResources(this.$route.params._id)
        .then((response) => {
          this.form = response.data
          console.log(response.data)
        })
        .catch(this.responseCatch)
        .finally(() => (this.loading = false))
    },
    async listResourcesTitle () {
      this.loading = true
      await getCourseTitle()
        .then(response => {
          console.log(response)
          this.listItemsCoursesTitle = response.data
        })
        .catch(this.responseCatch)
        .finally(() => (this.loading = false))
    },
    async onUpdate () {
      await updateResources(this.$route.params._id, this.form)
        .then((response) => {
          // let res = response.data
          this.$message({
            showClose: true,
            message: 'Archivo Actualizado!',
            type: 'success'
          })
          this.$router.push({ name: 'resources' })
        })
        .catch(this.responseCatch)
    },
    async postImage (param) {
      this.loading = true
      const formData = new FormData()
      formData.append('image', param.file)
      formData.append('width', '1200')
      formData.append('height', '900')
      formData.append('title', 'File-Avigrupo')
      formData.append('alt', 'File')
      uploadImageFile(formData).then((response) => {
        console.log(response)
        param.onSuccess(response.data.path)
      })
        .catch(response => {
          console.log(response)
          param.onError()
        })
        .finally(() => (this.loading = false))
    },
    handleAvatarSuccess (res, file) {
      this.form.image = res
      console.log(this.form.image)
    },
    async postAudio (param) {
      this.loading = true
      const formData = new FormData()
      formData.append('file', param.file)
      formData.append('folder', 'uploads/audio')
      formData.append('alt', 'Avigrupo-file')
      uploadFile(formData).then((response) => {
        console.log(response)
        param.onSuccess(response)
        this.$message({
          showClose: true,
          message: 'Se ha cargado el archivo!',
          type: 'success'
        })
      })
        .catch(response => {
          console.log(response)
          param.onError()
        })
        .finally(() => (this.loading = false))
    },
    handleAvatarSuccessAudio (res, file) {
      this.form.audio = res.path
    },
    async postFile (param) {
      this.loading = true
      const formData = new FormData()
      formData.append('file', param.file)
      formData.append('folder', 'uploads')
      uploadFile(formData).then((response) => {
        console.log(response)
        param.onSuccess(response)
      })
        .catch(response => {
          console.log(response)
          param.onError()
        })
        .finally(() => (this.loading = false))
    },
    handleAvatarSuccessFile (res, file) {
      this.form.file = res.path
    },
    clearFilter () {
      this.$refs.filterTable.clearFilter()
    }
  }
}
</script>
